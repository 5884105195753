import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				GymPro
			</title>
			<meta name={"description"} content={"Наше заведение может похвастаться широким выбором оборудования, разнообразными занятиями и персонализированными фитнес-программами, которые созданы для достижения ваших целей по физическому и психическому здоровью."} />
			<meta property={"og:title"} content={"GymPro"} />
			<meta property={"og:description"} content={"Наше заведение может похвастаться широким выбором оборудования, разнообразными занятиями и персонализированными фитнес-программами, которые созданы для достижения ваших целей по физическому и психическому здоровью."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/2-3%20%281%29.jpg?v=2024-06-17T09:11:31.542Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				МЕНЮ
			</Override>
		</Components.Header>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Расширение возможностей каждого вашего движения
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					В GymPro мы предоставляем широкий спектр услуг, разработанных для улучшения вашего фитнес-путешествия. От самого современного оборудования до специализированных занятий, каждое предложение разработано, чтобы помочь вам достичь ваших личных целей по здоровью и физической форме в благоприятной и динамичной среде.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1611672585731-fa10603fb9e0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Наши услуги
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Индивидуальные планы фитнеса: Специально разработанные для ваших целей независимо от того, хотите ли вы похудеть, набрать мышцы или улучшить общую физическую форму.
							<br />
							<br />
							{"\n\n"}Индивидуальный коучинг: Работайте с нашими сертифицированными тренерами для персонализированного руководства и поддержки.{"\n"}
							<br />
							<br />
							{"\n"}Групповые занятия Йога и пилатес: Повысьте гибкость, силу и ясность ума с помощью наших занятий ума и тела.{"\n"}
							<br />
							<br />
							{"\n"}Занятия спиннингом и кардиотренированием: Улучшайте здоровье сердечно-сосудистой системы с помощью наших высокоэнергетических групповых занятий.{"\n"}
							<br />
							<br />
							{"\n"}Аэробика: Следуйте веселым и энергичным процедурам, чтобы улучшить свою физическую форму в групповой обстановке.{"\n"}
							<br />
							<br />
							{"\n"}Учебные лагеря: Испытайте себя на наших занятиях на открытом воздухе, направленных на повышение силы и выносливости.{"\n"}
							<br />
							<br />
							{"\n"}Сила и кондиция Тренировка с отягощениями: Получите доступ к нашему широкому ассортименту свободных отягощений и тренажеров, разработанных для всех уровней.
							<br />
							<br />
							{"\n\n"}Функциональная тренировка: Улучшайте выносливость и силу с помощью высокоинтенсивных функциональных движений.{"\n"}
							<br />
							<br />
							{"\n"}Круговая тренировка: Попробуйте высокоинтенсивную круговую тренировку, которая повышает выносливость и скорость метаболизма.{"\n"}
							<br />
							<br />
							{"\n"}Улучшенные оздоровительные услуги Помимо традиционных предложений тренажерных залов GymPro сосредотачивается на целостном оздоровлении, которое дополняет вашу физическую подготовку.{"\n"}
							<br />
							<br />
							{"\n"}Консультации по вопросам питания Диетические планы: Получите индивидуальные диетические указания, чтобы улучшить результаты в форме.{"\n"}
							<br />
							<br />
							{"\n"}Семинары по питанию: Узнайте, как поддерживать здоровую диету с нашими экспертами-диетологами.

Психическое здоровье Семинары по управлению стрессом: Откройте для себя методы борьбы со стрессом и улучшение психического здоровья.{"\n"}
							<br />
							<br />
							{"\n"}Сеансы медитации под руководством: Присоединяйтесь к нашим занятиям, чтобы развивать внимательность и расслабление.{"\n"}
							<br />
							<br />
							{"\n"}Оздоровительные ретриты: Участвуйте в наших эксклюзивных ретритах, направленных на всестороннее оздоровление и омоложение.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/666ffc316bbd2000236b95fe/images/1-2.jpg?v=2024-06-17T09:11:31.510Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Узнайте больше с нами
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Это лишь краткий обзор того, что может предложить GymPro. Мы приглашаем вас посетить нас и ознакомиться с полным спектром наших услуг, разработанных для удовлетворения всех аспектов вашего здоровья и физической формы. Наш дружелюбный персонал всегда готов предоставить дополнительную информацию и помочь вам выбрать лучшие варианты для вашего путешествия по фитнесу.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакты
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});